<template>
  <div v-if="hasBlacklist" v-loading="getIsLoading" class="exporters-wrapper">
    <ControlTableBlacklistMobile
      v-if="isMobile"
      :table-data="tableData"
      :is-loading="getIsLoading"
      @editRow="handleEdit"
      @deleteRow="handleDelete"
      @selectDropdownMenu="handleTableMenu"
      @selectionChange="handleSelectionChange"
    />

    <div v-else class="control-table control-table-exporters">
      <div
        v-if="tableData.length || filteredPlateTruck"
        class="container container--full-screen"
      >
        <TableHeader
          :search="search"
          search-placeholder="Поиск по номеру авто"
          search-width="330px"
          @input="handleFilterInput"
          @clear="handleClearFilter"
          @search="handleSearchFilter"
        />
        <TableBody
          v-if="tableData.length"
          custom-class="exporters"
          :table-data="tableData"
          :table-fields-desktop="controlTableBlacklistFieldsDesktop"
          :table-dropdown-menu-list="controlTableBlacklistDropdownMenuList"
          :is-loading="getIsLoading"
          :has-icons="!isViewer && !isSupport"
          @editRow="handleEdit"
          @deleteRow="handleDelete"
          @selectDropdownMenu="handleTableMenu"
          @selectionChange="handleSelectionChange"
        >
          <template #default="slotProps">
            <TableCell
              :row="slotProps.data.row"
              :item="slotProps.data.row[slotProps.data.prop]"
              :prop-key="slotProps.data.prop"
              :status="slotProps.data.row.status"
              :width="slotProps.data.width"
              :min-width="slotProps.data.minWidth"
            />
          </template>
        </TableBody>

        <div v-else class="no-filtered">
          В списке нет автомобилей, соответствующих указанному фильтру
        </div>

        <TableFooter
          :pagination="pagination"
          text-quantity="Всего автомобилей:"
          item-names="автомобилей"
          @page="changePage"
          @size="changeSize"
        />
      </div>
      <TableEmpty
        v-if="!tableData.length && !filteredPlateTruck && !getIsLoading"
        warning="У вас не добавлено ни одного автомобиля в черный список"
        proposition="Добавить авто в ЧС"
        @add="addNewBlacklist"
      />
    </div>

    <DialogEditBlacklist :params="params" />
    <DialogRemoveTableRow :params="params" />
  </div>
  <div v-else class="no-filtered">
    На данном терминале не подключен черный список
  </div>
</template>

<script>
import {
  CONTROL_TABLE_BLACKLIST_EDIT_ROW,
  CONTROL_TABLE_BLACKLIST_REMOVE_ROW,
} from '@/constants/dialogs'
import {
  FETCH_BLACKLIST_PAGE,
  GET_BLACKLIST_PAGE,
  GET_BLACKLIST_PAGINATION,
  GET_IS_LOADING_BLACKLIST,
} from '@/views/control/store/actions'
import {
  GET_HAS_BLACKLIST,
  GET_IS_MOBILE,
  GET_TERMINAL_CURRENT_ID,
} from '@/store/actions'
import { TableBody, TableEmpty, TableFooter, TableHeader } from '@/UI/table'
import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'
import {
  controlTableBlacklistDropdownMenuList,
  controlTableBlacklistFieldsDesktop,
} from './data/ControlTableBlacklist'
import { mapActions, mapGetters } from 'vuex'
import ControlTableBlacklistMobile from './ControlTableBlacklistMobile'
import DialogEditBlacklist from './components/dialogs/dialog-edit-blacklist/DialogEditBlacklist'
import DialogRemoveTableRow from './components/dialogs/dialog-remove-table-row/DialogRemoveTableRow'
import TableCell from './components/TableCell'

export default {
  name: 'ControlTableBlackList',
  components: {
    DialogEditBlacklist,
    DialogRemoveTableRow,
    TableFooter,
    TableBody,
    TableHeader,
    TableCell,
    TableEmpty,
    ControlTableBlacklistMobile,
  },
  data() {
    return {
      controlTableBlacklistFieldsDesktop,
      controlTableBlacklistDropdownMenuList,
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      page: 1,
      perPage: 10,
      search: '',
      filteredPlateTruck: '',
    }
  },
  computed: {
    ...mapGetters({
      currentTerminal: GET_TERMINAL_CURRENT_ID,
      tableData: GET_BLACKLIST_PAGE,
      pagination: GET_BLACKLIST_PAGINATION,
      getIsLoading: GET_IS_LOADING_BLACKLIST,
      isMobile: GET_IS_MOBILE,
      hasBlacklist: GET_HAS_BLACKLIST,
    }),
    params() {
      return {
        plate_truck: this.filteredPlateTruck,
        page: this.page,
        'per-page': this.perPage,
      }
    },
  },
  watch: {
    currentTerminal() {
      if (this.hasBlacklist) {
        this.fetchBlacklist()
      }
    },
  },
  mounted() {
    if (this.hasBlacklist) {
      this.perPage = this.isMobile ? 999 : 10
      this.fetchBlacklist()
    }
  },
  methods: {
    ...mapActions({ blacklistFetch: FETCH_BLACKLIST_PAGE }),
    fetchBlacklist() {
      this.blacklistFetch(this.params)
    },
    handleFilterInput(value) {
      this.search = value
    },
    handleClearFilter() {
      this.search = ''
      this.handleSearchFilter()
    },
    handleSearchFilter() {
      this.filteredPlateTruck = this.search
      this.fetchBlacklist()
    },
    changePage(page = 1) {
      this.page = page
      this.fetchBlacklist()
    },
    changeSize(size = 10) {
      this.perPage = size
      this.fetchBlacklist()
    },
    handleEdit(row) {
      this.setDialog({
        name: CONTROL_TABLE_BLACKLIST_EDIT_ROW,
        visible: true,
        data: row,
      })
    },
    handleDelete({ id, plate_truck }) {
      this.setDialog({
        name: CONTROL_TABLE_BLACKLIST_REMOVE_ROW,
        visible: true,
        data: {
          isMultipleDelete: false,
          id,
          plate_truck,
        },
      })
    },
    handleTableMenu(command) {
      if (command === controlTableRemoveSelectedItems) {
        this.setDialog({
          name: CONTROL_TABLE_BLACKLIST_REMOVE_ROW,
          visible: true,
          data: {
            isMultipleDelete: true,
            idsList: this.multipleSelectionIds,
            namesList: this.multipleSelectionNames,
          },
        })
      }
      if (command === controlTableAddItem) {
        this.addNewBlacklist()
      }
    },
    handleSelectionChange({ ids, list }) {
      this.multipleSelectionIds = ids
      this.multipleSelectionNames = list.map(item => item.plate_truck)
    },
    addNewBlacklist() {
      this.filteredPlateTruck = ''
      this.setDialog({
        name: CONTROL_TABLE_BLACKLIST_EDIT_ROW,
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.control-table-exporters
  padding-top: 20px
  margin-top: 20px
.no-filtered
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  height: 200px
  color: $main-font
</style>
