var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasBlacklist
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.getIsLoading,
              expression: "getIsLoading",
            },
          ],
          staticClass: "exporters-wrapper",
        },
        [
          _vm.isMobile
            ? _c("ControlTableBlacklistMobile", {
                attrs: {
                  "table-data": _vm.tableData,
                  "is-loading": _vm.getIsLoading,
                },
                on: {
                  editRow: _vm.handleEdit,
                  deleteRow: _vm.handleDelete,
                  selectDropdownMenu: _vm.handleTableMenu,
                  selectionChange: _vm.handleSelectionChange,
                },
              })
            : _c(
                "div",
                { staticClass: "control-table control-table-exporters" },
                [
                  _vm.tableData.length || _vm.filteredPlateTruck
                    ? _c(
                        "div",
                        { staticClass: "container container--full-screen" },
                        [
                          _c("TableHeader", {
                            attrs: {
                              search: _vm.search,
                              "search-placeholder": "Поиск по номеру авто",
                              "search-width": "330px",
                            },
                            on: {
                              input: _vm.handleFilterInput,
                              clear: _vm.handleClearFilter,
                              search: _vm.handleSearchFilter,
                            },
                          }),
                          _vm.tableData.length
                            ? _c("TableBody", {
                                attrs: {
                                  "custom-class": "exporters",
                                  "table-data": _vm.tableData,
                                  "table-fields-desktop":
                                    _vm.controlTableBlacklistFieldsDesktop,
                                  "table-dropdown-menu-list":
                                    _vm.controlTableBlacklistDropdownMenuList,
                                  "is-loading": _vm.getIsLoading,
                                  "has-icons": !_vm.isViewer && !_vm.isSupport,
                                },
                                on: {
                                  editRow: _vm.handleEdit,
                                  deleteRow: _vm.handleDelete,
                                  selectDropdownMenu: _vm.handleTableMenu,
                                  selectionChange: _vm.handleSelectionChange,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (slotProps) {
                                        return [
                                          _c("TableCell", {
                                            attrs: {
                                              row: slotProps.data.row,
                                              item: slotProps.data.row[
                                                slotProps.data.prop
                                              ],
                                              "prop-key": slotProps.data.prop,
                                              status: slotProps.data.row.status,
                                              width: slotProps.data.width,
                                              "min-width":
                                                slotProps.data.minWidth,
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1404414410
                                ),
                              })
                            : _c("div", { staticClass: "no-filtered" }, [
                                _vm._v(
                                  " В списке нет автомобилей, соответствующих указанному фильтру "
                                ),
                              ]),
                          _c("TableFooter", {
                            attrs: {
                              pagination: _vm.pagination,
                              "text-quantity": "Всего автомобилей:",
                              "item-names": "автомобилей",
                            },
                            on: { page: _vm.changePage, size: _vm.changeSize },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.tableData.length &&
                  !_vm.filteredPlateTruck &&
                  !_vm.getIsLoading
                    ? _c("TableEmpty", {
                        attrs: {
                          warning:
                            "У вас не добавлено ни одного автомобиля в черный список",
                          proposition: "Добавить авто в ЧС",
                        },
                        on: { add: _vm.addNewBlacklist },
                      })
                    : _vm._e(),
                ],
                1
              ),
          _c("DialogEditBlacklist", { attrs: { params: _vm.params } }),
          _c("DialogRemoveTableRow", { attrs: { params: _vm.params } }),
        ],
        1
      )
    : _c("div", { staticClass: "no-filtered" }, [
        _vm._v(" На данном терминале не подключен черный список "),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }