import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'

export const controlTableBlacklistFieldsDesktop = [
  {
    prop: 'created_at',
    label: 'Дата добавления',
    align: 'center',
  },
  {
    prop: 'updated_at',
    label: 'Дата обновления',
    align: 'center',
  },
  { prop: 'plate_truck', label: 'Номер авто', width: '200px', align: 'center' },
  {
    prop: 'blocked_until',
    label: 'Срок блокировки',
    align: 'center',
  },
  {
    prop: 'reason',
    label: 'Причина добавления',
    width: '400px',
    align: 'center',
  },
  {
    prop: 'blocked_by',
    label: 'Кто добавил',
    align: 'center',
  },
]

export const controlTableBlacklistDropdownMenuList = [
  {
    id: 1,
    label: 'Удалить выбранные',
    value: controlTableRemoveSelectedItems,
    class: 'm-b-12',
  },
  {
    id: 2,
    label: 'Добавить машину',
    value: controlTableAddItem,
    isDivided: true,
  },
]
